// global style sheet
@import '/src/assets/scss/variables.scss';
@import '~@ng-select/ng-select/themes/default.theme.css';

:root {
  // body colors
  --i-body-background: #{$body-background};
  --i-body-background-shade-one: #{$body-background-shade-one};
  --i-body-background-shade-two: #{$body-background-shade-two};
  --i-body-background-shade-three: #{$body-background-shade-three};
  --i-body-foreground: #{$body-foreground};
  --i-body-foreground-shade-one: #{$body-foreground-shade-one};
  --i-body-foreground-shade-two: #{$body-foreground-shade-two};
  --i-body-foreground-shade-three: #{$body-foreground-shade-three};

  // primary colors
  --i-primary-background: #{$primary-background};
  --i-primary-background-shade-one: #{$primary-background-shade-one};
  --i-primary-background-shade-two: #{$primary-background-shade-two};
  --i-primary-foreground: #{$primary-foreground};

  // highlight colors
  --i-success-background: #{$success-background};
  --i-success-background-light: #{$success-light};
  --i-success-foreground: #{$success-foreground};

  --i-error-background: #{$error-background};
  --i-error-background-light: #{$error-light};
  --i-error-foreground: #{$error-foreground};

  --i-info-background: #{$info-background};
  --i-info-foreground: #{$info-foreground};

  --i-warning-background: #{$warning-background};
  --i-warning-foreground: #{$warning-foreground};

  --i-advanced-box-shadow-color: #{$body-background};
  --i-advanced-border-radius: #{$border-radius};

  // components ---------------
  --i-header-background: #{$body-background};
  --i-header-foreground: #{$body-foreground-shade-one};
  --i-header-label-background: #{$primary-background};
  --i-header-label-foreground: #{$primary-foreground};
  --i-header-secondary-text: #{$body-foreground-shade-two};
  --i-sidebar-background: #{$body-background-shade-one};
  --i-sidebar-foreground: #{$body-foreground-shade-one};
  --i-sidebar-active: #{$body-background-shade-three};
  --i-sidebar-hover: #{$body-background-shade-two};
  --i-nav-bar-background: #{$body-background-shade-one};
  --i-nav-bar-foreground: #{$body-foreground};
  --i-nav-bar-label-background: #{$primary-background};
  --i-nav-bar-label-foreground: #{$primary-foreground};
  --i-nav-bar-secondary-text: #{$body-foreground-shade-two};
  --i-action-primary-background: #{$primary-background};
  --i-action-primary-background-shade-one: #{$primary-background-shade-one};
  --i-action-primary-foreground: #{$primary-foreground};
  --i-action-primary-hover: #{$primary-background};
  --i-action-primary-active: #{$primary-background-shade-one};
  --i-action-primary-box-shadow-color: 'transparent';
  --i-action-primary-border-radius: #{$border-radius};
  --i-action-secondary-background: 'transparent'; //background transparent
  --i-action-secondary-foreground: #{$primary-foreground};
  --i-action-secondary-hover: #{$primary-background};
  --i-action-secondary-active: #{$primary-background-shade-one};
  --i-action-secondary-box-shadow-color: #{$box-shadow-color};
  --i-action-secondary-border-radius: #{$border-radius};
  --i-action-link-foreground: #{$body-foreground-shade-one};
  --i-action-link-hover: #{$primary-background};
  --i-badge-outline-background: #{$primary-background};
  --i-badge-outline-foreground: #{$primary-foreground};
  --i-badge-outline-border-radius: 50px;
  --i-badge-solid-background: #{$primary-background};
  --i-badge-solid-foreground: #{$primary-foreground};
  --i-badge-solid-border-radius: #{$border-radius};
  --i-card-background: #{$body-background-shade-one};
  --i-card-foreground: #{$body-foreground-shade-one};
  --i-card-secondary-text: #{$body-foreground-shade-two};
  --i-card-link-text: #{$body-foreground-shade-one};
  --i-card-border-width: 0;
  --i-card-border-color: #{$body-background-shade-one};
  --i-card-box-shadow-color: 'transparent';
  --i-card-border-radius: #{$border-radius};
  --i-card-label-one-background: #{$body-background-shade-three};
  --i-card-label-one-foreground: #{$body-foreground};
  --i-card-label-two-background: #{$body-background-shade-three};
  --i-card-label-two-foreground: #{$body-foreground};
  --i-card-label-three-background: #{$body-background-shade-three};
  --i-card-label-three-foreground: #{$body-foreground};
  --i-card-icon-background: #{$body-background-shade-two};
  --i-card-icon-foreground: #{$body-foreground};
  --i-card-icon-hover: #{$body-background-shade-three};
  --i-tabs-foreground: #{$body-foreground};
  --i-tabs-hover: #{$primary-foreground};
  --i-tabs-active: #{$body-foreground-shade-one};
  --i-bread-crumbs-foreground: #{$body-foreground};
  --i-bread-crumbs-active: #{$body-foreground-shade-one};
  --i-bread-crumbs-hover: #{$primary-background};
  --i-pagination-background: #{$primary-background};
  --i-pagination-foreground: #{$primary-foreground};
  --i-pagination-border-radius: #{$border-radius};
  --i-icon-with-bg-background: #{$body-background-shade-two};
  --i-icon-with-bg-foreground: #{$body-foreground};
  --i-card-with-bg-hover: #{$body-background-shade-three};
  --i-icon-without-bg-background: 'transparent';
  --i-icon-without-bg-foreground: #{$body-foreground};
  --i-icon-without-bg-hover: #{$body-background-shade-two};
  --i-icon-bw-background: 'transparent';
  --i-icon-bw-foreground: #{$body-foreground};
  --i-icon-bw-hover: #{$primary-background};
  --i-form-fields-background: 'transparent';
  --i-form-fields-foreground: #{$body-foreground-shade-one};
  --i-form-fields-placeholder-color: #{$body-foreground-shade-three};
  --i-form-fields-border-color: #{$body-foreground-shade-three};
  --i-form-fields-hover: #{$body-foreground-shade-one};
  --i-form-fields-focus: #{$primary-background};
  --i-form-fields-border-radius: #{$border-radius};
  --i-checks-and-radio-background: #{$body-background};
  --i-checks-and-radio-foreground: #{$body-foreground-shade-one};
  --i-checks-and-radio-border-color: #{$body-foreground-shade-three};
  --i-checks-and-radio-hover: #{$body-foreground-shade-one};
  --i-checks-and-radio-checked: #{$success-background};
  --i-checks-and-radio-border-radius: #{$border-radius};

  --i-white: #{$white};
  --i-dark: #{$dark};
  --i-black: #{$black};

  --i-taxonomy-icon-background: #{$taxonomy-icon-background};

  --i-font-regular: 'Satoshi Regular';
  --i-font-medium: 'Satoshi Medium';
  --i-font-bold: 'Satoshi Bold';

  --i-font-secondary-regular: 'Outfit Regular';
  --i-font-secondary-semibold: 'Outfit Semibold';
}

@font-face {
  font-family: 'Satoshi Regular';
  src:
    url(/assets/fonts/satoshi-fonts/Satoshi-Regular.woff2) format('woff2'),
    url(/assets/fonts/satoshi-fonts/Satoshi-Regular.woff) format('woff'),
    url(/assets/fonts/satoshi-fonts/Satoshi-Regular.ttf) format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi Medium';
  src:
    url(/assets/fonts/satoshi-fonts/Satoshi-Medium.woff2) format('woff2'),
    url(/assets/fonts/satoshi-fonts/Satoshi-Medium.woff) format('woff'),
    url(/assets/fonts/satoshi-fonts/Satoshi-Medium.ttf) format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi Bold';
  src:
    url(/assets/fonts/satoshi-fonts/Satoshi-Bold.woff2) format('woff2'),
    url(/assets/fonts/satoshi-fonts/Satoshi-Bold.woff) format('woff'),
    url(/assets/fonts/satoshi-fonts/Satoshi-Bold.ttf) format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Outfit Regular';
  src: url(/assets/fonts/outfit/Outfit-Regular.ttf);
  font-style: normal;
}

@font-face {
  font-family: 'Outfit Semibold';
  src: url(/assets/fonts/outfit/Outfit-SemiBold.ttf);
  font-style: normal;
}

body {
  @extend .font__regular;
  background-color: var(--i-body-background);
}

h1,
h2,
h3,
h4 {
  @extend .font__secondary__semibold;
}

h5,
h6 {
  @extend .font__bold;
}

p {
  @extend .font__regular;
}

.bg {
  &__white {
    background-color: var(--i-white);
  }

  &__body {
    background-color: var(--i-body-background);
  }
}

.text {
  &__primary {
    --bs-text-opacity: 1;
    color: var(--i-primary-background) !important;
  }

  &__primary__dark {
    color: var(--i-primary-background-shade-one) !important;
  }

  &__secondary {
    color: var(--i-primary-foreground) !important;
  }

  &__secondary__header {
    color: var(--i-header-label-foreground) !important;
  }

  &__secondary__navBar {
    color: var(--i-nav-bar-label-foreground) !important;
  }

  &__body {
    color: var(--i-body-foreground) !important;
  }

  &__body__shade__one {
    color: var(--i-body-foreground-shade-one) !important;
  }

  &__muted {
    color: var(--i-body-foreground-shade-two) !important;
  }

  &__error {
    color: var(--i-error-background) !important;
  }

  &__success {
    color: var(--i-success-background) !important;
  }

  &__success__foreground {
    color: var(--i-success-foreground) !important;
  }

  &__warning {
    color: var(--i-warning-background) !important;
  }

  &__info {
    color: var(--i-info-background) !important;
  }
}

.font {
  &__regular {
    font-family: var(--i-font-regular), sans-serif;
    font-weight: 400;
  }

  &__semibold {
    font-family: var(--i-font-medium), sans-serif;
    font-weight: 500;
  }

  &__bold {
    font-family: var(--i-font-bold), sans-serif;
    font-weight: 700;
  }

  &__secondary__semibold {
    font-family: var(--i-font-secondary-semibold), sans-serif;
    font-weight: 500;
  }

  &__secondary__regular {
    font-family: var(--i-font-secondary-regular), sans-serif;
    font-weight: 400;
  }

  &__ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// @Starts Global theme updates
body,
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1,
p {
  color: var(--i-body-foreground) !important;
}

.form-control,
.form-check-input,
input,
.ng-select .ng-select-container,
.ng-select.ng-select-opened>.ng-select-container,
.ng-dropdown-panel {
  background-color: var(--i-form-fields-background);
  color: var(--i-form-fields-foreground);
  border: var(--bs-border-width) solid var(--i-form-fields-border-color);

  &:disabled,
  &:disabled {
    background-color: var(--i-form-fields-background);
    opacity: 0.6;
  }

  &:not(:disabled):hover,
  &:not(.disabled):hover {
    border-color: var(--i-form-fields-hover);
  }

  &:focus {
    background-color: var(--i-form-fields-background);
    color: var(--i-form-fields-foreground);
    border-color: var(--i-form-fields-focus);
    box-shadow: none;
  }

  &::placeholder,
  .ng-placeholder {
    color: var(--i-form-fields-placeholder-color) !important;
  }
}

.ng-select {
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.form-control,
input,
.ng-select .ng-select-container,
.ng-select.ng-select-opened>.ng-select-container,
.ng-dropdown-panel,
.file-badge,
.dropzone {
  border-radius: var(--i-form-fields-border-radius);
}

.form-check-input {
  background-color: var(--i-checks-and-radio-background);
  border-color: var(--i-checks-and-radio-border-color);
  min-width: 16px;

  &.ng-touched.ng-invalid {
    border-color: var(--i-error-background);
  }

  &:hover:not(:disabled),
  &:hover:not(.disabled) {
    border-color: var(--i-checks-and-radio-hover);
  }

  &:focus {
    background-color: var(--i-checks-and-radio-focus);
    color: var(--i-body-foreground);
    box-shadow: none;
  }

  &:checked {
    border-color: var(--i-checks-and-radio-checked);
    background-color: var(--i-checks-and-radio-checked);
  }
}

.ng-select.ng-select-disabled {
  cursor: not-allowed;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: var(--i-body-background);
  opacity: 0.6;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--i-form-fields-placeholder-color);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: var(--i-body-background);
  color: var(--i-body-foreground);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: var(--i-primary-background-shade-one);
  color: var(--i-primary-foreground);
  font-weight: normal;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--i-form-fields-foreground) transparent transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: var(--i-body-background);
  color: var(--i-body-foreground);
  margin: auto;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  @extend .badge__solid;
}

.ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  color: var(--i-body-background-shade-three);
  border: 1px solid var(--i-body-background-shade-three) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid var(--i-badge-solid-foreground);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: var(--i-primary-background-shade-one);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
  color: var(--i-form-fields-foreground);
}

a,
.link {
  color: var(--i-action-link-foreground);

  &:hover {
    cursor: pointer;
    color: var(--i-action-link-hover) !important;
  }
}

.nav-link {
  color: var(--i-tabs-foreground);
}

.nav-tabs {
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--i-body-foreground-shade-three);
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--i-body-foreground-shade-three) !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--i-body-foreground-shade-three) !important;
}

.pagination {
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: transparent;
  --bs-pagination-border-color: var(--i-body-foreground-shade-three);
  --bs-pagination-hover-bg: var(--i-pagination-background);
  --bs-pagination-focus-bg: var(--i-pagination-background);
  --bs-pagination-hover-color: var(--i-pagination-foreground);
  --bs-pagination-focus-color: var(--i-pagination-foreground);
  --bs-pagination-active-color: var(--i-pagination-foreground);
  --bs-pagination-active-bg: var(--i-pagination-background);
  --bs-pagination-active-border-color: var(--i-pagination-background);
  --bs-pagination-disabled-border-color: var(--i-body-foreground-shade-one);
  --bs-pagination-border-radius: var(--i-pagination-border-radius);
}

.page-link {

  &:hover,
  &:active {
    color: var(--i-pagination-foreground) !important;
  }
}

.pagination-next .page-link {

  &:hover,
  &:active {
    background-color: var(--i-action-primary-background);
    color: var(--i-body-foreground);
  }
}

.sidebar__list__item i.icomoon {
  color: var(-i-icon-bw-foreground);
}

//for icon
.i-icon {
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.45s all ease-in-out;

  &:hover {
    background-color: var(--i-icon-with-bg-hover);
    color: var(--i-icon-with-bg-foreground) !important;
  }

  &--with--bg {
    color: var(--i-icon-with-bg-foreground) !important;
    background-color: var(--i-icon-with-bg-background) !important;

    &:hover {
      background-color: var(--i-icon-with-bg-hover) !important;
      color: var(--i-icon-with-bg-foreground) !important;
    }
  }

  &--hover--bg {
    color: var(--i-icon-without-bg-foreground) !important;
    background-color: transparent;

    &:hover {
      background-color: var(--i-icon-without-bg-hover);
      color: var(--i-icon-without-bg-foreground) !important;
    }
  }
}

.i-icon--bw {
  padding: 0.35rem;
  color: var(--i-icon-bw-foreground) !important;
  background-color: transparent;

  &:hover {
    color: var(--i-icon-bw-hover) !important;
  }
}

.i-icon--bg--not--branded {
  background-color: var(--i-white) !important;
  color: var(--i-dark) !important;
}

.icon-category-2 {
  color: var(--i-icon-without-bg-foreground);
  @extend .i-icon--hover--bg;
}

.circular__img--shadow--icon {
  @extend .i-icon--with--bg;
}

.i-card .circular__img--shadow--icon {
  cursor: pointer;
  background-color: var(--i-card-icon-background);
  color: var(--i-card-icon-foreground);

  &:hover {
    background-color: var(--i-card-icon-hover);
  }
}

.eye-icon {
  padding-right: 10px;

  &:hover {
    cursor: pointer;
  }
}

.icon-clipboard-copy:hover {
  cursor: pointer;
}

::placeholder {
  color: var(--i-body-foreground-shade-one) !important;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--i-body-foreground-shade-one) !important;
}

.page-item {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: 30%;
  }
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: transparent;
  border-color: var(--i-body-foreground);
}

.api-card__badge {
  &.badge {
    border-radius: var(--i-advanced-border-radius);
  }

  &.labelType1 {
    background-color: var(--i-card-label-one-background);
    color: var(--i-card-label-one-foreground);
  }

  &.labelType2 {
    background-color: var(--i-card-label-two-background);
    color: var(--i-card-label-two-foreground);
  }

  &.labelType3 {
    background-color: var(--i-card-label-three-background);
    color: var(--i-card-label-three-foreground);
  }
}

.table {
  --bs-table-color: var(--i-body-foreground);
  --bs-table-bg: var(--i-body-background);
  --bs-table-border-color: var(--i-body-foreground-shade-three);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--i-body-foreground);
  --bs-table-active-bg: var(--i-body-background-shade-two);
  --bs-table-hover-color: var(--i-body-foreground);
  --bs-table-hover-bg: var(--i-body-background-shade-two);
  border-color: var(--i-body-foreground-shade-three);
}

// @Ends Global theme updates

.line__clamp {

  &--2,
  &--3 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    box-orient: vertical;
  }

  &--2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  &--3 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

.badge {
  padding: 0.625rem 1.35rem 0.625rem 1.35rem;
  @extend .fs__14;

  &__outline {
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid var(--i-badge-outline-background) !important;
    color: var(--i-badge-outline-foreground);

    &__success {
      border: 1px solid var(--i-success-background) !important;
      color: var(--i-badge-success-foreground);
    }

    &__error {
      border: 1px solid var(--i-error-background) !important;
      color: var(--i-badge-error-foreground);
    }
  }

  &__solid {
    border-radius: var(--i-badge-solid-border-radius);
    background-color: var(--i-badge-solid-background);
    border: 1px solid var(--i-badge-solid-background) !important;
    color: var(--i-badge-solid-foreground);

    &__success {
      background-color: var(--i-success-background) !important;
      color: var(--i-badge-solid-foreground);
    }

    &__error {
      background-color: var(--i-error-background) !important;
      color: var(--i-badge-error-foreground);
    }
  }

  &__md {
    padding: 0.4rem 0.8rem;
    font-weight: 500;
  }

  &__sm {
    @extend .fs__12;
    font-weight: 500;
    padding: 0.2rem 0.6rem;
    line-height: unset;
  }
}

.divider-line {
  border-color: var(--i-body-foreground-shade-three);
  opacity: 1;
}

//this using for font to generate for dynamic font  size
@function pxToRem($pxSize) {
  @return #{calc($pxSize / 16)}rem;
}

@mixin font-size() {
  @for $size from 10 through 64 {
    .fs__#{$size} {
      font-size: pxToRem($size);
    }
  }
}

@include font-size();

// this is using for generate dynamic width percentage
@function percent($value) {
  @return calc(#{$value} * 1%);
}

@mixin width-percentage() {
  @for $percent from 1 through 100 {
    .width_p__#{$percent} {
      width: percent($percent);
    }
  }
}

@include width-percentage();

//End Here ===

.cursor {
  &-pointer {
    cursor: pointer;
  }

  &-default {
    cursor: default;
  }
}

.main__card {
  max-width: 550px;
}

.text__grey--700 {
  color: var(--bs-gray-700);
}

.form-text.text__error {
  color: var(--i-error-background) !important;
}

.form-control {
  padding: 0.55rem 1.25rem;
  font-size: 0.95rem;

  &.ng-touched.ng-invalid {
    border-color: var(--i-error-background);
  }
}

.ng-select {
  .ng-select-container {
    font-size: 0.95rem;
  }

  &.ng-select-single .ng-select-container {
    padding: 0.55rem 0.75rem;
    height: 42px;

    .ng-value-container .ng-input {
      top: 0.55rem;
      padding-left: 1.25rem;
    }
  }

  &.ng-select--sm.ng-select.ng-select-single .ng-select-container {
    padding: 0.2rem 0.35rem;
    height: 26px;
  }

  &.ng-select-multiple .ng-select-container {
    padding: 0.25rem 0.75rem;

    .ng-value-container .ng-placeholder {
      top: 7px;
    }
  }

  &.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-radius: var(--i-advanced-border-radius);
  }

  .ng-clear-wrapper {
    top: 1px;
    color: var(--i-body-foreground-shade-two);
  }

  &.ng-invalid.ng-touched {
    .ng-select-container {
      border-color: var(--i-error-background);
    }
  }
}

.ng-dropdown-panel {
  &.ng-select-bottom {
    top: 100%;
    border-radius: var(--bs-border-radius);
    margin-top: -4px;
    width: calc(100% - 20px);
    border: none;
    box-shadow: 1px 1px 5px var(--i-action-primary-box-shadow-color);
    margin-left: 10px;
  }

  .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: var(--i-primary-foreground);
    background-color: var(--i-primary-background);
  }
}

.btn {
  --bs-btn-border-radius: var(--i-action-primary-border-radius);
  box-shadow: 0 3px 6px var(--i-action-primary-box-shadow-color);
  @extend .fs__16;

  &-lg {
    --bs-btn-padding-y: 0.75rem;
    font-size: 1.15rem;
  }

  &.btn-primary {
    --bs-btn-color: var(--i-action-primary-foreground);
    --bs-btn-bg: var(--i-action-primary-background);
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--i-action-primary-foreground);
    --bs-btn-hover-bg: var(--i-action-primary-hover);
    --bs-btn-hover-border-color: var(--i-action-primary-hover);
    // --bs-btn-focus-shadow-rgb: 47, 128, 158;
    --bs-btn-active-color: var(--i-action-primary-foreground);
    --bs-btn-active-bg: var(--i-action-primary-active);
    --bs-btn-active-border-color: var(--i-action-primary-active);
    --bs-btn-disabled-color: var(--i-action-primary-foreground);
    --bs-btn-disabled-bg: var(--i-action-primary-background);
    --bs-btn-disabled-border-color: var(--i-action-primary-background);
    --bs-btn-font-weight: 500;
    background: linear-gradient(var(--bs-btn-bg),
        var(--i-action-primary-background-shade-one));
    border: none;

    &:hover {
      background: var(--bs-btn-hover-bg);
      animation: fadeoutBg 2s;
    }

    @keyframes fadeoutBg {
      from {
        background-color: var(--i-action-primary-background-shade-one);
      }

      to {
        background-color: var(--bs-btn-hover-bg);
      }
    }

    &:active,
    &:focus {
      background: var(--bs-btn-active-bg);
    }

    &:disabled,
    &.disabled {
      background: var(--bs-btn-disabled-bg);
      opacity: 50%;
    }
  }

  &.btn-outline-primary {
    --bs-btn-color: var(--i-action-secondary-foreground);
    --bs-btn-border-color: var(--i-action-secondary-foreground);
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-color: var(--i-action-secondary-hover);
    --bs-btn-hover-border-color: var(--i-action-secondary-hover);
    --bs-btn-active-bg: transparent;
    --bs-btn-active-color: var(--i-action-secondary-active);
    --bs-btn-active-border-color: var(--i-action-secondary-active);
    --bs-btn-disabled-color: var(--i-action-secondary-foreground);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--i-action-secondary-foreground);
    --bs-btn-focus-shadow-rgb: 10, 105, 141; //TODO: advance customization
    --bs-btn-font-weight: 500;

    &:hover {
      color: var(-bs-btn-hover-color);
      background-color: transparent;
      border-color: var(-bs-btn-hover-border-color);
    }

    &:active,
    &:focus {
      border-color: var(--bs-btn-active-color);
      color: var(--bs-btn-active-border-color);
    }

    &:disabled,
    &.disabled {
      color: var(--bs-btn-disabled-color);
      border-color: var(--bs-btn-disabled-border-color);
      opacity: 50%;
    }
  }

  &.btn-neutral {
    --bs-btn-color: var(--i-body-background);
    --bs-btn-bg: var(--i-body-foreground-shade-two);
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--i-body-background);
    --bs-btn-hover-bg: var(--i-body-foreground-shade-two);
    --bs-btn-hover-border-color: var(--i-body-foreground-shade-two);
    --bs-btn-active-color: var(--i-body-background);
    --bs-btn-active-bg: var(--i-body-foreground-shade-two);
    --bs-btn-active-border-color: var(--i-body-foreground-shade-two);
    --bs-btn-disabled-color: var(--i-body-background);
    --bs-btn-disabled-bg: var(--i-body-foreground-shade-two);
    --bs-btn-disabled-border-color: var(--i-body-foreground-shade-two);
    --bs-btn-font-weight: 500;
    --bs-btn-disabled-opacity: 0.75;
    background: linear-gradient(var(--i-body-foreground-shade-two),
        var(--i-body-foreground-shade-one));
    border: none;

    &:hover {
      background: var(--i-body-foreground-shade-two);
      animation: fadeoutBgNeutral 2s;
    }

    @keyframes fadeoutBgNeutral {
      from {
        background-color: var(--i-body-foreground-shade-one);
      }

      to {
        background-color: var(--i-body-foreground-shade-two);
      }
    }

    &:active,
    &:focus {
      background: var(--bs-btn-active-bg);
    }

    // &:disabled,
    // &.disabled {
    //   background: var(--bs-btn-disabled-bg);
    //   opacity: 50%;
    // }
  }

  &.btn-success {
    --bs-btn-color: var(--i-success-foreground);
    --bs-btn-bg: var(--i-success-background);
    --bs-btn-border-color: var(--i-success-background);
    --bs-btn-hover-color: var(--i-success-foreground);

    &:hover {
      background-color: var(--i-success-background-light);
      border-color: var(--i-success-background-light);
    }
  }

  &.btn-danger {
    --bs-btn-color: var(--i-error-foreground);
    --bs-btn-bg: var(--i-error-background);
    --bs-btn-border-color: var(--i-error-background);
    --bs-btn-hover-color: var(--i-error-foreground);

    &:hover {
      background-color: var(--i-error-background-light);
      border-color: var(--i-error-background-light);
    }
  }

  &.icomoon {
    color: var(--i-action-primary-foreground);
  }
}

.i-link,
.i-link a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.icomoon {
  text-decoration: none;
}

.i-card {
  box-shadow: 0px 2px 9px var(--i-card-box-shadow-color);
  border: none;
  --bs-card-border-radius: var(--i-card-border-radius);
  --bs-card-inner-border-radius: calc(var(--i-card-border-radius) - (var(--bs-border-width)));

  .card,
  &.card {
    background-color: var(--i-card-background);
    color: var(--i-card-foreground);
    border: var(--i-card-border-width) solid var(--i-card-border-color);

    .card-body {
      background-color: transparent;
    }
  }

  .text__muted {
    color: var(--i-card-secondary-text) !important;
  }

  .i-icon,
  .gc-table__action__icon__label {
    color: var(--i-card-icon-foreground);
  }
}

.container__grey {
  background-color: var(--i-body-background-shade-one);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.i-field__group {
  position: relative;

  .i-field,
  .form-control {
    padding-right: 40px;
  }

  .i-field__postfix {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 42px;
    padding: 0 10px;
  }
}

.i-search-field__group {
  position: relative;

  .i-field,
  .form-control {
    padding-left: 40px;
  }

  .i-field__prefix {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 42px;
    padding: 0 10px;
  }

  .i-field__postfix {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 42px;
    padding: 0 10px;
  }
}

.i-table {
  thead th {
    background-color: var(--i-body-background-shade-one);
    position: relative;
  }

  thead th,
  tbody td {
    padding: 1rem 1.25rem;
    vertical-align: middle;
  }

  th a.i-table__sort {
    padding-right: 18px;
    position: absolute;
    margin-right: 0;
    right: 2px;
    margin: auto;
    height: 100%;
    top: 0;
  }

  a.i-table__sort:before,
  a.i-table__sort:after {
    border: 4px solid transparent;
    content: '';
    display: block;
    height: 0;
    right: 5px;
    top: 50%;
    position: absolute;
    width: 0;
  }

  a.i-table__sort {
    &:before {
      border-bottom-color: var(--i-body-foreground-shade-three);
      margin-top: -9px;
    }

    &.i-table__sort-active-asc:before {
      border-bottom-color: var(--i-primary-background-shade-two);
    }

    &:after {
      border-top-color: var(--i-body-foreground-shade-three);
      margin-top: 1px;
    }

    &.i-table__sort-active-desc:after {
      border-top-color: var(--i-primary-background-shade-two);
    }
  }

  tr {

    &>td:first-child,
    &>th:first-child {
      border-left: 1px solid var(--bs-table-border-color);
    }

    &>td:last-child,
    &>th:last-child {
      border-right: 1px solid var(--bs-table-border-color);
    }

    th {
      border-top: 1px solid var(--bs-table-border-color);
    }
  }
}

.itoast__parent {
  position: fixed;
  right: 80px;
  top: 116px;
  z-index: 1057;
}

.i-pagination {
  .page-link {
    padding: 0.45rem 0.9rem;
    font-weight: 600;
    display: flex;
    height: 42px;
    align-items: center;
  }

  .page-item {
    &:not(.active) .page-link {
      color: var(--i-body-foreground-shade-two);
    }

    &:first-child .page-link,
    &:last-child .page-link {
      font-size: 1.6rem;
      padding: 0.2rem 0.9rem 0.5rem 0.9rem;
      color: var(--i-body-foreground-shade-two) !important;
    }
  }

  .page-link.active,
  .active>.page-link {
    z-index: 1;
    border-top-color: var(--i-body-foreground-shade-two);
    border-bottom-color: var(--i-body-foreground-shade-two);
  }
}

.circular__image {
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  border-radius: 50%;
  height: auto;
  padding: 0.5rem;
  flex: 0 0 44px;
}

.circular__img--shadow {
  @extend .circular__image;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.i-modal.modal,
.i-modal {
  .modal-header {
    margin: 0 0.5rem;
    padding: 1rem 1.25rem;
    border-bottom: 1px solid var(--i-body-foreground-shade-three);

    h6 {
      margin: 0;
    }
  }

  .modal-footer {
    border-top: none;
  }

  .btn-close {
    color: var(--i-body-foreground);
    border-radius: 20px;
    font-size: 1.2rem;
    padding: 0;
    --bs-btn-close-bg: none;
    opacity: 1;

    &:hover {
      opacity: 0.75;
    }
  }
}

.modal-content {
  border: none;
  background-color: var(--i-body-background);
  color: var(--i-body-foreground);
}

.i-rating {
  color: var(--i-warning-background);
  font-size: 1.5rem;

  &--lg {
    font-size: 1.5rem;
  }
}

.i-radio-btn-group {
  input {
    display: none;
  }

  .btn-primary {
    background-color: transparent;
    color: var(--i-primary-background);

    &.active {
      border-color: var(--i-primary-background);
      background-color: var(--i-primary-background);
      color: var(--i-primary-foreground);
    }
  }

  .btn-primary-solid {
    color: var(--i-body-foreground-shade-two);
    border: 1px solid var(--i-action-primary-background);
  }

  &>input:disabled+label {
    pointer-events: none;
    background-color: transparent;
    border-color: var(--i-body-foreground-shade-one);
    color: var(--i-body-foreground-shade-one);
    opacity: 0.4;
  }

  &> :not(.btn-check)+.btn:nth-child(2) {
    border-top-left-radius: var(--i-advanced-border-radius) !important;
    border-bottom-left-radius: var(--i-advanced-border-radius) !important;
  }
}

.i-accordion {
  .btn-link {
    padding: 0;
    text-decoration: none;
    transition: all 0.5s ease-in-out;

    &::after {
      font-family: 'icomoon' !important;
      content: '\e90a';
      position: absolute;
      right: 0;
      transform: rotate(90deg);
    }
  }

  .panel-title {
    position: relative;
  }

  .panel-open {
    .btn-link {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }
}

.i-progress {
  --bs-progress-bar-bg: var(--i-primary-background);
  --bs-progress-bg: var(--i-body-foreground-shade-one);
  --bs-progress-border-radius: var(--i-advanced-border-radius);
}

.i-breadcrumb {
  color: var(--i-bread-crumbs-foreground);

  &-item+&-item::before {
    content: '\e90a';
    font-family: 'icomoon';
    color: var(--i-bread-crumbs-foreground);
    font-size: 13px;
    line-height: 2;
  }

  &-item a,
  &-item.current .link {
    color: var(--i-bread-crumbs-active) !important;
    text-decoration: none;
  }

  &-item a:hover {
    color: var(--i-bread-crumbs-hover) !important;
    text-decoration: underline;
  }
}

.i-dropdown-menu {
  &.dropdown-menu {
    --bs-dropdown-color: var(--i-body-foreground);
    --bs-dropdown-bg: var(--i-body-background);
    --bs-dropdown-border-color: var(--i-body-background-shade-two); //TODO: need to clarify the border color
    --bs-dropdown-divider-bg: var(--i-body-background-shade-two);
    --bs-dropdown-link-color: var(--i-body-foreground);
    --bs-dropdown-link-hover-color: var(--i-body-foreground-shade-two); //TODO: add link active color
    --bs-dropdown-link-hover-bg: var(--i-body-background-shade-two);
    --bs-dropdown-link-active-color: var(--i-body-background-shade-two); //TODO: add link active color
    --bs-dropdown-link-active-bg: var(--i-body-foreground-shade-one); //TODO: add link active color
    --bs-dropdown-link-disabled-color: var(--i-body-foreground);
    --bs-dropdown-border-radius: var(--i-advanced-border-radius);
    --bs-dropdown-inner-border-radius: calc(var(--i-advanced-border-radius) - var(--bs-border-width));
    --bs-dropdown-box-shadow: 0px 3px 8px var(--i-advanced-box-shadow-color);
    box-shadow: 0px 3px 8px var(--i-advanced-box-shadow-color);
  }
}

.custom__disable {
  pointer-events: none;
  opacity: 0.85;
}

.full-page-spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Gateway connections Table styles (used in UI customisation + Manage gateway components
.gc-table {
  &__row {
    padding: 0.4rem 1.5rem;
    border-radius: var(--i-advanced-border-radius);
    margin-bottom: 15px;
    border: 1px solid var(--i-body-background-shade-two);
  }

  &__icon {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 50%;
    width: 40px;
    height: auto;
    padding: 0.5rem;
    margin-right: 20px;
    flex: 0 0 44px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title a {
    text-decoration: none;
    color: var(--i-body-foreground);

    &:hover {
      color: var(--i-card-link-text) !important;
      text-decoration: underline;
    }
  }

  &__right {
    line-height: 44px;

    &__badge {
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }

    &__status {
      padding: 0 20px;
      margin-left: auto;

      p {
        font-size: 0.85rem;
      }
    }

    &__action {
      border-left: 1px solid var(--i-body-foreground-shade-three);
      padding: 0 0 0 20px;
      flex: 0.65;

      i {
        font-size: 1.2rem;
      }

      >div {
        flex: 1;
      }
    }

    p {
      line-height: normal;
    }
  }

  &__action__icon__label {
    font-size: 0.8rem;
    color: var(--i-card-icon-foreground);
    line-height: initial;
    text-align: center;
  }
}

df-messenger {
  z-index: 999;
  position: fixed;
  bottom: 33px;
  right: 16px;
  // global color
  --df-messenger-font-family: var(--i-font-regular);
  --df-messenger-chat-background: var(--i-body-background-shade-one);

  // bubble
  --df-messenger-chat-bubble-background: var(--i-action-primary-background);
  --df-messenger-chat-bubble-icon-color: var(--i-action-primary-foreground);
  --df-messenger-internal-chat-bubble-size: 56px;
  --df-messenger-chat-bubble-icon-size: 30px;
  --df-messenger-chat-bubble-size: 56px;
  --df-messenger-internal-chat-window-offset: 6px;

  // title
  --df-messenger-titlebar-background: var(--i-body-background);
  --df-messenger-titlebar-font-color: var(--i-body-foreground);
  --df-messenger-titlebar-icon-font-color: var(--i-body-foreground);

  // chat bot user/bot text message
  --df-messenger-message-user-background: var(--i-primary-background-shade-one);
  //
  --df-messenger-message-bot-background: var(--i-body-background);

  // footer input
  --df-messenger-input-background: var(--i-body-background-shade-one);
  --df-messenger-internal-input-font-family: var(--i-font-regular);
  --df-messenger-input-padding: 10px;
  --df-messenger-input-box-background: var(--i-body-background);
  --df-messenger-internal-input-font-color: var(--i-body-foreground) !important;
  --df-messenger-input-box-border: var(--i-form-fields-foreground);
  --df-messenger-input-box-border-radius: var(--i-form-fields-border-radius);
  --df-messenger-primary-color: var(--i-primary-background);
  --df-messenger-send-icon-color-disabled: var(--i-primary-background-shade-one);
  --df-messenger-default-font-color: var(--i-body-foreground);
}

.container__wrapper {
  width: 100%;
  padding-right: 4.5rem;
  padding-left: 4.5rem;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
}

.custom-class-tooltip .tooltip-inner {
  background-color: var(--i-body-background-shade-one);
  color: var(--i-body-foreground);
  padding: 0.9375rem;
  text-align: left;
}

.custom-class-tooltip .arrow::before {
  right: 0;
  border-right-color: var(--i-body-background-shade-one);
}

@media only screen and (max-width: 768px) {
  .itoast__parent {
    right: 40px;
  }
}

.resource-listing-header {
  font-size: 18px;
  font-weight: 600;
}