@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?lxuw3w');
  src:
    url('fonts/icomoon.eot?lxuw3w#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lxuw3w') format('truetype'),
    url('fonts/icomoon.woff?lxuw3w') format('woff'),
    url('fonts/icomoon.svg?lxuw3w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-entitlements:before {
  content: '\e972';
}

.icon-minus-check-box-collapse:before {
  content: '\e970';
}

.icon-plus-check-box-expand:before {
  content: '\e971';
}

.icon-add-resources:before {
  content: '\e96f';
}

.icon-api-version-content:before {
  content: '\e96e';
}

.icon-business-areas:before {
  content: '\e96d';
}

.icon-manage-approval:before {
  content: '\e96a';
}

.icon-iFlow:before {
  content: '\e969';
}

.icon-custom-roles:before {
  content: '\e968';
}

.icon-assign-user:before {
  content: '\e954';
}

.icon-feature-dac_security:before {
  content: '\e94c';
}

.icon-feature-ai_search:before {
  content: '\e964';
}

.icon-feature-ai_test_sdk:before {
  content: '\e965';
}

.icon-feature-ai_auto_docs:before {
  content: '\e967';
}

.icon-reload:before {
  content: '\e936';
}

.icon-feature-dapper:before {
  content: '\e962';
}

.icon-feature-management:before {
  content: '\e961';
}

.icon-feature-analytics:before {
  content: '\e960';
}

.icon-feature-admin_api:before {
  content: '\e95e';
}

.icon-feature-virtual_gateway:before {
  content: '\e95f';
}

.icon-feature-sso_openid:before {
  content: '\e95b';
}

.icon-feature-sso_saml:before {
  content: '\e95c';
}

.icon-feature-speedpost:before {
  content: '\e95d';
}

.icon-feature-ui_customisation:before {
  content: '\e966';
}

.icon-feature-events:before {
  content: '\e957';
}

.icon-feature-behaviour_as_a_service:before {
  content: '\e958';
}

.icon-feature-api_as_a_service:before {
  content: '\e959';
}

.icon-feature-subscriptions:before {
  content: '\e95a';
}

.icon-feature-api_security:before {
  content: '\e952';
}

.icon-feature-new-feature:before {
  content: '\e953';
}

.icon-feature-monetization:before {
  content: '\e955';
}

.icon-feature-sandbox:before {
  content: '\e956';
}

.icon-feature-api_mesh:before {
  content: '\e950';
}

.icon-feature-ai_chatbot:before {
  content: '\e951';
}

.icon-feature-gw_connectivity:before {
  content: '\e94d';
}

.icon-feature-analytics_on_demand:before {
  content: '\e94e';
}

.icon-feature-ai_affinity:before {
  content: '\e94f';
}

.icon-favorite:before {
  content: '\e949';
}

.icon-favorite-fill:before {
  content: '\e948';
}

.icon-access-denied:before {
  content: '\e945';
}

.icon-analytics-access:before {
  content: '\e944';
}

.icon-data-cleaning:before {
  content: '\e943';
}

.icon-product:before {
  content: '\e935';
}

.icon-google:before {
  content: '\e942';
}

.icon-github-mark:before {
  content: '\e946';
}

.icon-pingid:before {
  content: '\e947';
}

.icon-lock-user:before {
  content: '\e94a';
}

.icon-okta:before {
  content: '\e94b';
}

.icon-check-green:before {
  content: '\e941';
}

.icon-sso-config:before {
  content: '\e940';
}

.icon-api-affinity:before {
  content: '\e93e';
}

.icon-dashboard-manage-api:before {
  content: '\e915';
}

.icon-dashboard-view-analtics:before {
  content: '\e937';
}

.icon-dashboard-discover-api:before {
  content: '\e93c';
}

.icon-dashboard-manage-gateway:before {
  content: '\e93d';
}

.icon-question-1:before {
  content: '\e93b';
}

.icon-clipboard-copy:before {
  content: '\e93a';
}

.icon-settings-configuration:before {
  content: '\e938';
}

.icon-settings-customisation:before {
  content: '\e939';
}

.icon-version:before {
  content: '\e933';
}

.icon-supply-chain:before {
  content: '\e934';
}

.icon-documentation:before {
  content: '\e932';
}

.icon-table-view:before {
  content: '\e930';
}

.icon-grid-view:before {
  content: '\e931';
}

.icon-env-dev:before {
  content: '\e92e';
}

.icon-env-pre-prod:before {
  content: '\e92f';
}

.icon-folder:before {
  content: '\e92b';
}

.icon-file:before {
  content: '\e92c';
}

.icon-down-arrow:before {
  content: '\e92d';
}

.icon-web-traffic-analytics:before {
  content: '\e92a';
}

.icon-meat:before {
  content: '\e929';
}

.icon-download-contract:before {
  content: '\e922';
}

.icon-no-data-file .path1:before {
  content: '\e923';
  color: rgb(232, 232, 232);
}

.icon-no-data-file .path2:before {
  content: '\e924';
  margin-left: -0.9990234375em;
  color: rgb(186, 186, 186);
}

.icon-no-data-file .path3:before {
  content: '\e925';
  margin-left: -0.9990234375em;
  color: rgb(186, 186, 186);
}

.icon-no-data-file .path4:before {
  content: '\e926';
  margin-left: -0.9990234375em;
  color: rgb(186, 186, 186);
}

.icon-no-data-cloud .path1:before {
  content: '\e927';
  color: rgb(232, 232, 232);
}

.icon-no-data-cloud .path2:before {
  content: '\e928';
  margin-left: -1.125em;
  color: rgb(198, 198, 198);
}

.icon-donut-chart-1:before {
  content: '\e91d';
}

.icon-business-and-trade:before {
  content: '\e910';
}

.icon-corporate:before {
  content: '\e91c';
}

.icon-info:before {
  content: '\e911';
}

.icon-person:before {
  content: '\e91e';
}

.icon-reset-password:before {
  content: '\e91f';
}

.icon-api-analytics:before {
  content: '\e920';
}

.icon-user-analytics:before {
  content: '\e921';
}

.icon-search:before {
  content: '\e91a';
}

.icon-import-api:before {
  content: '\e91b';
}

.icon-download:before {
  content: '\e917';
}

.icon-document:before {
  content: '\e918';
}

.icon-arrow-right:before {
  content: '\e90a';
}

.icon-arrow-left:before {
  content: '\e90b';
}

.icon-arrow-double-right:before {
  content: '\e90c';
}

.icon-arrow-double-left:before {
  content: '\e912';
}

.icon-team-management-converted:before {
  content: '\e916';
}

.icon-open-eye:before {
  content: '\e902';
}

.icon-dashboard-2:before {
  content: '\e909';
}

.icon-closed-eye:before {
  content: '\e914';
}

.icon-cloud-upload:before {
  content: '\e919';
}

.icon-dashboard-1:before {
  content: '\e90e';
}

.icon-cancel-error:before {
  content: '\e900';
}

.icon-check-success:before {
  content: '\e901';
}

.icon-share:before {
  content: '\e903';
}

.icon-fetch-api:before {
  content: '\e904';
}

.icon-warning:before {
  content: '\e905';
}

.icon-close:before {
  content: '\e906';
}

.icon-pencil:before {
  content: '\e907';
}

.icon-delete:before {
  content: '\e908';
}

.icon-global-access:before {
  content: '\e90d';
}

.icon-setting:before {
  content: '\e90f';
}

.icon-system-settings:before {
  content: '\e913';
}

.icon-api-affinity1:before {
  content: '\e93f';
}

.icon-reset-gray:before {
  content: '\e963';
}

.icon-plus:before {
  content: '\e96c';
}

.icon-manage-groups:before {
  content: '\e96b';
}

.icon-arrow-left-square-fill:before {
  content: '\e973';
}
